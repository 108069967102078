import { message, notification } from 'antd';
import { getRequest, postRequest } from 'app/axiosClient';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';

import {
  getCategorySectorList,
  getCategorySectorListFailed,
  getCategorySectorListSuccess,
  getCitiesByProvince,
  getCitiesByProvinceFailed,
  getCitiesByProvinceSuccess,
  getDistrictByCity,
  getDistrictByCityFailed,
  getDistrictByCitySuccess,
  getOngoingProject,
  getOngoingProjectFailed,
  getOngoingProjectSuccess,
  getProjectListBusiness,
  getProjectListBusinessFailed,
  getProjectListBusinessSuccess,
  getProvinces,
  getProvincesFailed,
  getProvincesSuccess,
  getQuestionDetail,
  getQuestionDetailFailed,
  getQuestionDetailSuccess,
  getTemplateEveryPage,
  getTemplateEveryPageFailed,
  getTemplateEveryPageSuccess,
  postOTPPhone,
  postOTPPhoneFailure,
  postOTPPhoneLoading,
  postOTPPhoneSuccess,
  postSelectedOption,
  postSelectedOptionFailed,
  postSelectedOptionSuccess,
  postSendEmailError,
  postSendEmailErrorFailed,
  postSendEmailErrorSuccess,
  postTemplateEveryPage,
  postTemplateEveryPageFailed,
  postTemplateEveryPageReset,
  postTemplateEveryPageSuccess,
  resendOTPPhone,
  resendOTPPhoneFailure,
  resendOTPPhoneLoading,
  resendOTPPhoneSuccess,
  setLoading,
} from './CreateProjectSlice';
import { JARVIS_ERROR, OTP_ERROR } from 'assets/constant/error-message';

function* getCategorySectorListAPI(action) {
  yield put(
    setLoading({
      name: 'categoryListPayload',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest('pdd/selection?source=mini_prefs&page=1', true),
    );

    yield put(
      getCategorySectorListSuccess({
        name: 'categoryListPayload',
        data: res.data?.data,
        pageInfo: res.data?.page_info,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_methodology'),
    });
    yield put(
      getCategorySectorListFailed({
        name: 'categoryListPayload',
      }),
    );
  }
}

function* getProjectListBusinessAPI(action) {
  yield put(
    setLoading({
      name: 'projectBusinessList',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('fund/projects?page=1&size=2', true));
    yield put(
      getProjectListBusinessSuccess({
        name: 'projectBusinessList',
        data: res.data.data,
      }),
    );
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_project_list'),
    });
    yield put(
      getProjectListBusinessFailed({
        name: 'projectBusinessList',
      }),
    );
  }
}

function* getOngoingProjectAPI(action) {
  yield put(
    setLoading({
      name: 'OngoingProject',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/${action.payload.id}/progress`, true),
    );
    yield put(
      getOngoingProjectSuccess({
        name: 'OngoingProject',
        data: res.data,
      }),
    );
  } catch (e) {
    yield put(
      getOngoingProjectFailed({
        name: 'OngoingProject',
      }),
    );
  }
}

function* getProvincesAPI() {
  yield put(
    setLoading({
      name: 'provinces',
    }),
  );
  try {
    let res;
    res = yield call(() => getRequest('locations/provinces', true));
    yield put(
      getProvincesSuccess({
        name: 'provinces',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getProvincesFailed({
        name: 'provinces',
      }),
    );
  }
}

function* getCitiesByProvinceAPI(action) {
  yield put(
    setLoading({
      name: 'cities',
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `locations/cities?province_id=${action.payload.province_id}`,
        true,
      ),
    );
    yield put(
      getCitiesByProvinceSuccess({
        name: 'cities',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getCitiesByProvinceFailed({
        name: 'cities',
      }),
    );
  }
}

function* getDistrictByCityAPI(action) {
  yield put(
    setLoading({
      name: 'district',
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(`locations/districts?city_id=${action.payload.city_id}`, true),
    );
    yield put(
      getDistrictByCitySuccess({
        name: 'district',
        data: res?.data?.data,
      }),
    );
  } catch (e) {
    yield put(
      getDistrictByCityFailed({
        name: 'district',
      }),
    );
  }
}

function* getQuestionDetailAPI(action) {
  yield put(
    setLoading({
      name: 'QuestionDetailPayload',
      status: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/${action.payload.id}/template/${action.payload.page}`,
        true,
      ),
    );

    yield put(
      getQuestionDetailSuccess({
        name: 'QuestionDetailPayload',
        data: res.data?.components[0],
        animation: res.data?.metadata?.animation_href,
        selected: res.data?.components[0]?.last_value,
        title: res.data.name,
        pageInfo: res.data?.page,
      }),
    );
  } catch (e) {
    yield put(
      getQuestionDetailFailed({
        name: 'QuestionDetailPayload',
        title: e.data.name,
        message: e.data.message,
      }),
    );
  }
}
function* postSelectedOptionAPI(action) {
  yield put(
    setLoading({
      name: 'SelectedOptionPayload',
      status: true,
    }),
  );
  try {
    let res;
    const { history } = action.payload;
    res = yield call(() =>
      postRequest(
        `simple-pdd/${action.payload.id}/template/${action.payload.page}`,
        action.payload.data,
      ),
    );

    yield put(
      postSelectedOptionSuccess({
        SelectedOptionPayload: res.data,
      }),
    );
    if (res.data) {
      if (res.data.next_page !== 0) {
        history.push({
          pathname: '/create-project',
          search: `?id=${res.data.sub_sector_id}&page=${res.data.next_page}`,
        });
      }
    }
  } catch (e) {
    notification['error']({
      message: i18n.t('selected_option_error'),
    });
    yield put(
      postSelectedOptionFailed({
        name: 'SelectedOptionPayload',
      }),
    );
  }
}

function* getTemplateEveryPageAPI(action) {
  try {
    const { params } = action.payload;
    const res = yield call(() =>
      getRequest(
        `simple-pdd/${params.id}/template/${params?.page}${
          params.source === 'report' ? '?source=report' : ''
        }`,
        true,
      ),
    );
    yield put(
      getTemplateEveryPageSuccess({
        name: action.payload.name,
        projectId: res?.data?.project_id,
        response: {
          data: res?.data,
          pageInfo: res?.data?.page_info,
        },
      }),
    );
  } catch (e) {
    yield put(
      getTemplateEveryPageFailed({
        name: action.payload.name,
        response: {
          data: e.data,
        },
      }),
    );
  }
}

function* postTemplateEveryPageAPI(action) {
  const { params } = action.payload;
  try {
    const res = yield call(() =>
      postRequest(
        `simple-pdd/${params?.id}/template/${params?.page}${
          params?.source === 'report' ? '?source=report' : ''
        }`,
        action.payload.body,
      ),
    );
    yield put(
      postTemplateEveryPageSuccess({
        data: res.data,
      }),
    );
  } catch (e) {
    if (e?.data && e?.data?.error) {
      yield put(
        postTemplateEveryPageFailed({
          data: e,
          isJarvisError: JARVIS_ERROR.includes(e.data.error),
          isOTPError: OTP_ERROR.includes(e.data.error),
        }),
      );
    }
  } finally {
    yield put(postTemplateEveryPageReset());
  }
}

function* postSendEmailErrorAPI(action) {
  const { params } = action.payload;
  try {
    yield call(() =>
      postRequest(
        `simple-pdd/${params?.id}/send-error-email`,
        action.payload.body,
      ),
    );
    yield put(postSendEmailErrorSuccess());
    message.success(i18n.t('submit_contact_success'));
  } catch (e) {
    yield put(
      postSendEmailErrorFailed({
        isOTPError: OTP_ERROR.includes(e.data.error),
      }),
    );
  }
}

function* OTPPhoneAPI(action) {
  yield put(postOTPPhoneLoading());
  try {
    let res;
    res = yield call(() =>
      postRequest('user/verify-otp', action.payload, true),
    );

    yield put(
      postOTPPhoneSuccess({
        data: res.data,
      }),
    );
    if (!res?.data?.is_end_of_page) {
      message.success(i18n.t('verify_contact_success'));
    }
  } catch (e) {
    yield put(postOTPPhoneFailure(e.data?.message));
  }
}

function* resendOTPPhoneAPI(action) {
  yield put(resendOTPPhoneLoading(true));
  try {
    yield call(() => postRequest('user/resend-otp', action.payload, true));

    yield put(resendOTPPhoneSuccess());
    notification['success']({
      message: i18n.t('resend_otp_success'),
    });
  } catch (e) {
    yield put(resendOTPPhoneFailure(e.data.message));
    notification['error']({
      message: i18n.t('resend_otp_failed'),
      description: i18n.t(e.data.message),
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(getCategorySectorList, getCategorySectorListAPI),
    takeLatest(getProjectListBusiness, getProjectListBusinessAPI),
    takeLatest(getQuestionDetail, getQuestionDetailAPI),
    takeLatest(postSelectedOption, postSelectedOptionAPI),
    takeLatest(getTemplateEveryPage, getTemplateEveryPageAPI),
    takeLatest(getOngoingProject, getOngoingProjectAPI),
    takeLatest(getProvinces, getProvincesAPI),
    takeLatest(getCitiesByProvince, getCitiesByProvinceAPI),
    takeLatest(getDistrictByCity, getDistrictByCityAPI),
    takeLatest(postTemplateEveryPage, postTemplateEveryPageAPI),
    takeLatest(postSendEmailError, postSendEmailErrorAPI),
    takeLatest(postOTPPhone, OTPPhoneAPI),
    takeLatest(resendOTPPhone, resendOTPPhoneAPI),
  ]);
}
