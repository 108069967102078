import { notification } from 'antd';
import { deleteRequest, getRequest } from 'app/axiosClient';
import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  deleteReport,
  reportDetail,
  reportDetailFailed,
  reportDetailReset,
  reportDetailSuccess,
  reportList,
  reportListEmpty,
  reportListFailed,
  reportListLoading,
  reportListSuccess,
} from './reportSlice';

function* reportListAPI(action) {
  yield put(
    reportListLoading({
      formLoading: true,
    }),
  );
  try {
    let res;
    res = yield call(() =>
      getRequest(
        `simple-pdd/reports${
          action.payload ? `?${action.payload}&size=10` : ''
        }`,
      ),
    );

    if (res?.status === 204) {
      yield put(
        reportListEmpty({
          reportEmpty: true,
        }),
      );
    } else if (res?.status === 200) {
      yield put(
        reportListSuccess({
          reportList: res?.data,
          reportEmpty: false,
        }),
      );
    }
  } catch (e) {
    notification['error']({
      message: i18n.t('failed_display_report_list'),
    });
    yield put(reportListFailed());
  }
}

function* reportDetailAPI(action) {
  try {
    let res;
    res = yield call(() =>
      getRequest(`simple-pdd/reports/${action.payload.reportId}`),
    );

    if (res.status === 200) {
      yield put(
        reportDetailSuccess({
          loading: !res.data?.data_ready?.is_data_ready,
          ready: res.data?.data_ready?.is_data_ready,
          data: res.data,
        }),
      );
    }
  } catch (e) {
    notification['error']({
      message: e?.data?.message || i18n.t('error_get_report_detail'),
    });
    yield put(reportDetailFailed());
    yield put(reportDetailReset());
  }
}

function* deleteReportAPI(action) {
  try {
    let res = yield call(() =>
      deleteRequest(`simple-pdd/reports/${action.payload}`),
    );
    if (res.status === 200) {
      notification['success']({
        message: i18n.t('delete_report_success'),
      });
    }
  } catch (e) {
    notification['error']({
      message: e?.data?.message || i18n.t('delete_report_failed'),
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(reportList, reportListAPI),
    takeLatest(reportDetail, reportDetailAPI),
    takeLatest(deleteReport, deleteReportAPI),
  ]);
}
