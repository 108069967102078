import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reportListLoading: true,
  reportList: [],
  reportListStatus: null,
  reportEmpty: false,
  idReportDetail: null,
  pageValue: 1,
  loadReport: false,
  selectedIdReport: [],
  refetchReport: false,
  showModalDeleteReport: false,
  reportDetailLoading: true,
  reportDetailStatus: null,
  reportDetailData: null,
  reportDetailReady: false,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    reportListLoading(state, action) {
      state.reportListLoading = action.payload.reportListLoading;
      state.reportList = [];
    },
    reportList(state) {
      state.formLoading = true;
      state.projectReferenceStatus = null;
    },
    reportListEmpty(state, action) {
      state.formLoading = false;
      state.reportEmpty = action.payload.reportEmpty;
      state.reportListStatus = 'Success';
    },
    reportListSuccess(state, action) {
      state.formLoading = false;
      state.reportList = action.payload.reportList;
      state.reportEmpty = action.payload.reportEmpty;
      state.reportListStatus = 'Success';
    },
    reportListFailed(state) {
      state.formLoading = false;
      state.reportListStatus = 'Fail';
    },
    deleteReport() {},
    setPageValue(state, actions) {
      state.pageValue = actions.payload.page;
    },
    setLoadReport(state, actions) {
      state.loadReport = actions.payload;
    },
    setSelectedIdReport(state, actions) {
      state.selectedIdReport = actions.payload;
    },
    setShowModalDeleteReport(state, actions) {
      state.showModalDeleteReport = actions.payload;
    },
    setRefetchReport(state, actions) {
      state.refetchReport = actions.payload;
    },
    reportDetail(state) {
      state.reportDetailLoading = true;
      state.reportDetailReady = false;
    },
    reportDetailSuccess(state, actions) {
      state.reportDetailLoading = actions.payload.loading;
      state.reportDetailData = actions.payload.data;
      state.reportDetailReady = actions.payload.ready;
      state.reportDetailStatus = 'success';
    },
    reportDetailFailed(state) {
      state.reportDetailLoading = false;
      state.reportDetailData = null;
      state.reportDetailReady = true;
      state.reportDetailStatus = 'failed';
    },
    reportDetailReset(state) {
      state.reportDetailLoading = true;
      state.reportDetailData = null;
      state.reportDetailReady = false;
      state.reportDetailStatus = null;
    },
    setIdReportDetail(state, actions) {
      state.idReportDetail = actions.payload;
    },
  },
});

export const {
  reportListLoading,
  reportList,
  reportListEmpty,
  reportListSuccess,
  reportListFailed,
  setPageValue,
  deleteReport,
  setLoadReport,
  setSelectedIdReport,
  setShowModalDeleteReport,
  setRefetchReport,
  reportDetail,
  reportDetailSuccess,
  reportDetailFailed,
  setIdReportDetail,
  reportDetailReset,
} = reportSlice.actions;
export default reportSlice.reducer;
