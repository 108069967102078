import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Grid, Image, Space } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SwitcherWrapper = styled.div`
  && {
    .switcher {
      span {
        color: #fff;
      }
    }
    .switcher-light {
      span {
        color: #000;
      }
    }
  }
`;
const { useBreakpoint } = Grid;

const SwitcherLanguage = (props) => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  const { lg } = useBreakpoint();
  const availableLanguages = ['id', 'en'];

  useEffect(() => {
    const storedLanguage = localStorage.getItem('lang')
      ? JSON.parse(localStorage.getItem('lang'))
      : {};
    if (storedLanguage && availableLanguages.includes(storedLanguage)) {
      changeLanguage(storedLanguage);
      window.localStorage.setItem('lang', JSON.stringify(storedLanguage));
    }
  }, [language]);

  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem('lang', JSON.stringify(lang));
    window.localStorage.setItem('lang_updated', JSON.stringify(true));
    changeLanguage(lang);
  };

  const flag = {
    id: <Image preview={false} src="/id-flag.svg" alt="id-flag" />,
    en: (
      <Image
        height={21}
        style={{ marginLeft: -1 }}
        preview={false}
        src="/en-flag.svg"
        alt="en-flag"
      />
    ),
  };

  const items = [
    {
      key: '1',
      label: (
        <a
          href="window.location"
          style={{ marginLeft: 15 }}
          onClick={(e) => {
            e.preventDefault();
            handleChangeLanguage('id');
          }}
        >
          ID
        </a>
      ),
      icon: flag['id'],
    },
    {
      key: '2',
      label: (
        <a
          href="window.location"
          onClick={(e) => {
            e.preventDefault();
            handleChangeLanguage('en');
          }}
          style={{ marginLeft: 15 }}
        >
          EN
        </a>
      ),
      icon: flag['en'],
    },
  ];

  return (
    <div>
      <SwitcherWrapper>
        <Dropdown
          trigger={['click']}
          menu={{
            items,
          }}
        >
          <a href="window.location" onClick={(e) => e.preventDefault()}>
            <Space
              className={props.islight ? 'switcher-light' : 'switcher'}
              id="switcher-lang"
            >
              {language && flag[language]}
              {lg ? <span>{language?.toUpperCase()}</span> : null}
              <DownOutlined
                style={{
                  color: props.islight ? '#000' : '#fff',
                  fontSize: '11px',
                  marginBottom: '8px',
                }}
              />
            </Space>
          </a>
        </Dropdown>
      </SwitcherWrapper>
    </div>
  );
};

export default React.memo(SwitcherLanguage);
